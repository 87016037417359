import utilActions from '@/api/actions';

const cancelPortcall = {
	id: 'cancelPortcall',
	selectionType: 'single',
	label: 'cancelPortcall.label',
	functionality: 'CANCEL_PORTCALLS',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return (
				(registries && registries[0].portcallstatus === 1) ||
				(registries && registries[0].portcallstatus === 4) ||
				(registries && registries[0].portcallstatus === 5) ||
				(registries && registries[0].portcallstatus === 6)
			);
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, row);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-enableVessel-' + model.name + '-show', row);
	}
};

const updateanchorageAction = {
	id: 'updateanchorageAction',
	selectionType: 'single',
	label: 'updateanchorage.label',
	functionality: 'UPDATE_PORTCALLS_ANCHORAGE',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return registries && registries[0].portcallstatus !== 0;
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, row);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-updateanchorage-' + model.name + '-show', row);
	}
};

const modifEtaAction = {
	id: 'modifEtaAction',
	selectionType: 'single',
	label: 'modifeta.label',
	functionality: 'CHANGE_PORTCALLS_ETA',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return registries && (registries[0].portcallstatus === 1 || registries[0].portcallstatus === 5 || registries[0].portcallstatus === 4);
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, row);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-modifeta-' + model.name + '-show', row);
	}
};

const modifEtdAction = {
	id: 'modifEtdAction',
	selectionType: 'single',
	label: 'modifetd.label',
	functionality: 'CHANGE_PORTCALLS_ETD',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return (
				registries &&
				(registries[0].portcallstatus === 1 ||
					registries[0].portcallstatus === 2 ||
					registries[0].portcallstatus === 4 ||
					registries[0].portcallstatus === 5 ||
					registries[0].portcallstatus === 7 ||
					registries[0].portcallstatus === 8)
			);
		}
		return false;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = utilActions.getPkFromModel(model, row);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-modifetd-' + model.name + '-show', row);
	}
};

const rejectPortCall = {
	id: 'rejectPortCall',
	selectionType: 'single',
	label: 'rejectPortCall.label',
	functionality: 'REJECT_PORTCALLS',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return registries && registries[0].portcallstatus === 4;
		}
		return false;
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;

		this.$puiEvents.$emit('pui-modalDialogForm-rejectPortCall-' + model.name + '-show', row);
	}
};

const confirmPortCall = {
	id: 'confirmPortCall',
	selectionType: 'single',
	label: 'confirmPortCall.label',
	functionality: 'CONFIRM_PORTCALLS',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return registries && registries[0].portcallstatus === 4;
		}
		return false;
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;

		this.$puiEvents.$emit('pui-modalDialogForm-confirmPortCall-' + model.name + '-show', row);
	}
};

const confirmDeparture = {
	id: 'confirmDeparture',
	selectionType: 'single',
	label: 'action.confirmDeparture',
	functionality: 'CONFIRM_PORTCALLS',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return registries && registries[0].portcallstatus === 7;
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, row);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-confirmDeparture-' + model.name + '-show', row);
	}
};

const viewTransferLog = {
	id: 'viewTransferLog',
	selectionType: 'single',
	label: 'action.viewTransferLog',
	functionality: 'VIEW_TRANSFER_LOGS',
	checkAvailability: function () {
		return true;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, row);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-viewTransferLog-' + model.name + '-show', row);

		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
		const params = {
			router: this.$router,
			url: `/shipagencytransfers`,
			params: null,
			query: {},
			replace: false
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
		localStorage.setItem('portcallid', row.portcallid);
	}
};

const shipAgencyTransfer = {
	id: 'shipAgencyTransfer',
	selectionType: 'single',
	label: 'action.shipAgencyTransfer',
	functionality: 'SHIP_AGENCY_TRANSFER',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return registries && registries[0].satstatus != 'P' && registries[0].portcallstatus != 0 && registries[0].portcallstatus != 3;
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;
		row.registries = registries[0];

		this.$puiEvents.$emit('pui-modalDialogForm-shipAgencyTransfer-' + model.name + '-show', row);
	}
};

const approveTransfer = {
	id: 'approveTransfer',
	selectionType: 'single',
	label: 'action.confirmTransfer',
	functionality: 'APPROVE_TRANSFER',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return registries && registries[0].satstatus == 'P';
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;
		row.registries = registries[0];

		this.$puiEvents.$emit('pui-modalDialogForm-approveTransfer-' + model.name + '-show', row);
	}
};

const rejectTransfer = {
	id: 'rejectTransfer',
	selectionType: 'single',
	label: 'action.rejectTransfer',
	functionality: 'REJECT_TRANSFER',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return registries && registries[0].satstatus == 'P';
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.remarks = registries[0].remarks;
		row.pk = registries[0].portcallid;
		row.registries = registries[0];

		this.$puiEvents.$emit('pui-modalDialogForm-rejectTransfer-' + model.name + '-show', row);
	}
};

const emergencyStart = {
	id: 'emergencyStart',
	selectionType: 'single',
	label: 'action.emergencystart',
	functionality: 'WRITE_EMERGENCYPROCESS',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			const registry = registries[0];
			if (registry.emergencystatus !== 'O' && registry.locode !== 'GESPS') {
				if (registry.portcallstatus !== 0 && registry.portcallstatus !== 3) {
					return (
						registries[0].locode === 'GEBUS' ||
						registries[0].locode === 'GEKUL' ||
						(registries[0].locode === 'GEPTI' && registries[0].anchoragestatus == 'Yes')
					);
				}
			}
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, row);
		row.headerPk = objectPk;
		row.isAction = true;
		row.pk = registries[0].portcallid;
		row.registries = registries[0];

		this.$puiEvents.$emit('pui-modalDialogForm-emergencyStart-' + model.name + '-show', row);
	}
};

const emergencyEnd = {
	id: 'emergencyEnd',
	selectionType: 'single',
	label: 'action.emergencyend',
	functionality: 'WRITE_EMERGENCYPROCESS',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return (
				registries &&
				registries[0].emergencystatus == 'O' &&
				(!registries[0].portcallstatus == 0 || !registries[0].portcallstatus == 3) &&
				(registries[0].locode === 'GEBUS' || registries[0].locode === 'GEKUL' || registries[0].locode === 'GEPTI')
			);
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, row);
		row.headerPk = objectPk;
		row.isAction = true;
		row.pk = registries[0].portcallid;
		row.registries = registries[0];

		this.$puiEvents.$emit('pui-modalDialogForm-emergencyEnd-' + model.name + '-show', row);
	}
};

export default {
	actions: [
		cancelPortcall,
		modifEtaAction,
		modifEtdAction,
		updateanchorageAction,
		confirmPortCall,
		rejectPortCall,
		confirmDeparture,
		viewTransferLog,
		shipAgencyTransfer,
		approveTransfer,
		rejectTransfer,
		emergencyStart,
		emergencyEnd
	],
	formactions: [
		cancelPortcall,
		modifEtaAction,
		modifEtdAction,
		updateanchorageAction,
		confirmPortCall,
		rejectPortCall,
		confirmDeparture,
		viewTransferLog,
		shipAgencyTransfer,
		approveTransfer,
		rejectTransfer,
		emergencyStart,
		emergencyEnd
	]
};
