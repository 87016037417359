<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:modelName="modelName"
			:onOk="onOkDisable"
			:dialogName="dialogNameEnableVessel"
			:titleText="$t('portcalls1.titleText')"
			:widthDialog="800"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`portcalls-observations`"
							v-model="data.modalData.observations"
							:label="$t('modal.version_remarks')"
							:disabled="formDisabled"
							toplabel
							required
							maxlength="256"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="$t('action.modifeta')"
			:modelName="modelName"
			:dialogName="dialogNameModifEta"
			v-model="modalData"
			:widthDialog="800"
			:onOk="onOkModifEta"
			:onShow="onShoModifEta"
		>
			<template slot="message">
				<v-flex xs12 md6>
					<pui-date-field
						:id="`eta${idSufix}`"
						:label="$t('modal.eta')"
						v-model="modalData.eta"
						toplabel
						required
						time
						:max="modalData.etd"
						:min="new Date()"
					></pui-date-field>
				</v-flex>
				<v-flex xs12 md8>
					<pui-checkbox
						:id="`etaconfirmed${idSufix}`"
						:label="$t('modal.etaconfirmed')"
						v-model="modalData.etaconfirmed"
						true-value="1"
						false-value="0"
						:disabled="dissableCheck"
					></pui-checkbox>
				</v-flex>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="$t('action.updateanchorage')"
			:modelName="modelName"
			:dialogName="dialogNameUpdateAnchorage"
			v-model="modalData"
			:widthDialog="800"
			:onOk="onOkUpdateAnchorage"
		>
			<template slot="message">
				<v-row style="margin-top: 35px">
					<v-flex xs12 lg6 x6>
						<pui-date-field
							:id="`arrivalata-${modelName}`"
							:label="$t('portcalls.arribalATA')"
							v-model="modalData.arrataanchorage"
							toplabel
							time
							:max="new Date()"
						></pui-date-field>
					</v-flex>
					<v-flex xs12 md12 lg6 x6>
						<pui-date-field
							:id="`arrivalatd-${modelName}`"
							:label="$t('portcalls.arrivalATD')"
							v-model="modalData.arratdanchorage"
							toplabel
							time
							:min="modalData.arrataanchorage"
							:max="new Date()"
						></pui-date-field>
					</v-flex>
					<v-flex xs12 md12 lg6 x6>
						<pui-date-field
							:id="`departureata-${modelName}`"
							:label="$t('portcalls.depatureATA')"
							v-model="modalData.depataanchorage"
							toplabel
							time
							:max="new Date()"
						></pui-date-field>
					</v-flex>

					<v-flex xs12 md12 lg6 x6>
						<pui-date-field
							:id="`depatureatd-${modelName}`"
							:label="$t('portcalls.departureATD')"
							v-model="modalData.depatdanchorage"
							toplabel
							time
							:min="modalData.depataanchorage"
							:max="new Date()"
						></pui-date-field>
					</v-flex>
					<v-flex xs12 md12 lg6 x6>
						<pui-date-field
							:id="`departureata-${modelName}`"
							:label="$t('portcalls.emeataanchorage')"
							v-model="modalData.emeataanchorage"
							toplabel
							time
							:max="new Date()"
						></pui-date-field>
					</v-flex>

					<v-flex xs12 md12 lg6 x6>
						<pui-date-field
							:id="`depatureatd-${modelName}`"
							:label="$t('portcalls.emeatdanchorage')"
							v-model="modalData.emeatdanchorage"
							toplabel
							time
							:min="modalData.emeataanchorage"
							:max="new Date()"
						></pui-date-field>
					</v-flex>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="$t('action.modifetd')"
			:modelName="modelName"
			:dialogName="dialogNameModifEtd"
			v-model="modalData"
			:widthDialog="800"
			:onOk="onOkModifEtd"
		>
			<template slot="message">
				<v-flex xs12 md6>
					<pui-date-field
						:id="`etd${idSufix}`"
						:label="$t('modal.etd')"
						v-model="modalData.etd"
						toplabel
						required
						time
						:min="!isCreatingElement ? modalData.eta : null"
					></pui-date-field>
				</v-flex>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.confirmPortCall')"
			:modelName="modelName"
			:dialogName="dialogNameconfirmPortCall"
			:widthDialog="800"
			:onOk="onOkconfirmPortCall"
		>
			<template slot="message">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						{{ $t('action.confirmPortCallmesasage') }}
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.rejectPortCall')"
			:modelName="modelName"
			:dialogName="dialogNamerejectPortCall"
			:widthDialog="800"
			:onOk="onOkrejectPortCall"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`portcalls-rejectionreason`"
							v-model="data.modalData.rejectionreason"
							:label="$t('modal.rejectionreason')"
							toplabel
							required
							maxlength="256"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('confirmDeparture.title')"
			:modelName="modelName"
			:dialogName="dialogNameconfirmDeparture"
			:widthDialog="800"
			:onOk="onOkconfirmDeparture"
		>
			<template slot="message">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						{{ $t('confirmDeparture.label') }}
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('action.shipAgencyTransfer')"
			:modelName="modelName"
			:dialogName="dialogNameShipAgencyTransfer"
			:onOk="onOktransferShipAgency"
			:onShow="onShowTransferShipAgency"
			:overflow="false"
			:widthDialog="600"
		>
			<template slot="message">
				<v-layout wrap>
					<v-flex xs12 v-show="false">
						<pui-select
							id="portcode"
							attach="portcode"
							:label="this.$t('portcalls.portcode')"
							toplabel
							v-model="newModel"
							modelName="mlocalports"
							reactive
							disabled
							clearable
							required
							:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
							:itemsToSelect="itemsToSelectPortcode"
							:itemValue="['countrycode', 'portcode']"
							:itemText="(item) => `${item.countrycode}${item.portcode} - ${item.portdescription}`"
							:order="{ locode: 'asc' }"
							@change="changePortCode($event, modalData)"
						></pui-select>
					</v-flex>
					<v-flex xs12>
						<pui-select
							:id="`shipagendid-${modelName}`"
							:attach="`shipagendid-${modelName}`"
							:label="$t('portcalls.shipagendid')"
							toplabel
							clearable
							reactive
							required
							v-model="newModel"
							modelName="vlupagency"
							:itemsToSelect="itemsToSelectShipagendid"
							:modelFormMapping="{ locode: 'locode' }"
							:itemValue="['descripcion']"
							:itemText="(item) => `${item.descripcion}`"
							:order="{ descripcion: 'asc' }"
							:fixedFilter="fixedFilterAgent"
							@change="changeAgent($event, modalData)"
						></pui-select>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('action.confirmTransfer')"
			:modelName="modelName"
			:dialogName="dialogNameConfirmTransfer"
			:widthDialog="800"
			:onOk="onOkConfirmTransfer"
		>
			<template slot="message">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						{{ $t('shipagencytransfers.confirmTransferMessage') }}
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('action.rejectTransfer')"
			:modelName="modelName"
			:dialogName="dialogNameRejectTransfer"
			:widthDialog="800"
			:onOk="onOkRejectTransfer"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`shipagencytransfers-rejectionreason`"
							v-model="data.modalData.rejectionreason"
							:label="$t('shipagencytransfers.rejectionreason')"
							toplabel
							required
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('action.emergencystart')"
			:modelName="modelName"
			:dialogName="dialogNameEmergencyStart"
			:widthDialog="900"
			:onOk="onOkEmergencyStart"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-row dense>
						<v-flex xs12 md12>
							<pui-date-field
								:id="`emergencyprocess-datestart`"
								:label="$t('emergencyprocess.datestart')"
								v-model="data.modalData.datestart"
								toplabel
								required
								time
								:max="new Date()"
							></pui-date-field>
						</v-flex>

						<v-flex xs12 md12>
							<pui-text-area
								:id="`emergencyprocess-remarksstart`"
								v-model="data.modalData.remarksstart"
								:label="$t('emergencyprocess.remarksstart')"
								toplabel
								required
							></pui-text-area>
						</v-flex>
					</v-row>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('action.emergencyend')"
			:modelName="modelName"
			:dialogName="dialogNameEmergencyEnd"
			:widthDialog="900"
			:onOk="onOkEmergencyEnd"
			:onShow="onShowEmergencyEnd"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-row dense>
						<v-flex xs12 md12>
							<pui-date-field
								:id="`emergencyprocess-dateend`"
								:label="$t('emergencyprocess.dateend')"
								v-model="data.modalData.dateend"
								toplabel
								required
								time
								:min="emergencystartdate"
							></pui-date-field>
						</v-flex>

						<v-flex xs12 md12>
							<pui-text-area
								:id="`emergencyprocess-remarksend`"
								v-model="data.modalData.remarksend"
								:label="$t('emergencyprocess.remarksend')"
								toplabel
								required
							></pui-text-area>
						</v-flex>
					</v-row>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'portcallsmodals',
	data() {
		return {
			modal: {
				enableVessel: {
					labelText: 'prueba',
					disableOk: false,
					show: false
				}
			},
			modalData: {},
			model: 'portcalls',
			modelemergencyprocess: 'emergencyprocess',
			dialogNameEnableVessel: 'enableVessel',
			dialogNameModifEta: 'modifeta',
			dialogNameModifEtd: 'modifetd',
			dialogNameUpdateAnchorage: 'updateanchorage',
			dialogNameImmigrationValidateDocs: 'arrvalidatedocs',
			dialogNameconfirmPortCall: 'confirmPortCall',
			dialogNamerejectPortCall: 'rejectPortCall',
			dialogNameconfirmDeparture: 'confirmDeparture',
			dialogNameShipAgencyTransfer: 'shipAgencyTransfer',
			dialogNameConfirmTransfer: 'approveTransfer',
			dialogNameRejectTransfer: 'rejectTransfer',
			dialogNameEmergencyStart: 'emergencyStart',
			dialogNameEmergencyEnd: 'emergencyEnd',
			idSufix: `-${this.modelName}-modal`,
			dissableCheck: '',
			observations: '',
			consigneeList: [],
			consigneeKey: 0,
			newModel: {
				countrycode: null,
				portcode: null
			},
			valuesModel: 0,
			shipagendname: null
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	computed: {
		itemsToSelectPortcode() {
			return [{ countrycode: this.newModel.countrycode, portcode: this.newModel.portcode }];
		},
		itemsToSelectShipagendid() {
			return [{ codorg: this.newModel.shipagendid, descripcion: this.newModel.shipagendname }];
		},
		fixedFilterAgent() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'descripcion', op: 'ne', data: this.shipagendname },
							{ field: 'locode', op: 'ne', data: this.newModel.countrycode + this.newModel.portcode }
						]
					}
				]
			};
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-disableVessel-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-disableVessel-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-disableVessel-${this.modelName}-show`);
		this.$puiEvents.$off(`pui-modalDialog-enableVessel-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-enableVessel-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-enableVessel-${this.modelName}-show`);
	},
	methods: {
		onShowTransferShipAgency(modalData) {
			this.shipagendname = modalData.registries.shipagendname;
			this.newModel.countrycode = modalData.registries.countrycode;
			this.newModel.portcode = modalData.registries.portcode;
		},
		changeAgent(e) {
			if (e != null) {
				this.newModel.shipagendname = e.descripcion;
				this.newModel.shipagendid = e.codorg;
			}
		},
		changePortCode(e) {
			if (e != null) {
				this.valuesModel = 1;
				const urlEquipmentServices = '/gestaut/getUserConsignee';
				const params = {
					countrycode: e.countrycode,
					portcode: e.portcode
				};
				this.$puiRequests.getRequest(urlEquipmentServices, params, (response) => this.setConsignee(response.data));
			} else {
				this.newModel.portcode = null;
				this.newModel.countrycode = null;
				this.valuesModel = 0;
			}
		},
		setConsignee(responseConsignee = []) {
			this.consigneeList = [];
			this.consigneeList = responseConsignee.map((c) => {
				if (c.descripcion != this.shipagendname) {
					return {
						codorg: c.codorg,
						descripcion: c.descripcion,
						label: c.descripcion
					};
				}
			});
			this.consigneeKey++;
		},
		onOkDisable(modalData) {
			const url = '/portcalls/cancelPortcalls';
			const title = this.$puiI18n.t('cancelPortcall.title');
			const params = {
				portcallid: modalData.headerPk.portcallid,
				observations: modalData.observations
			};

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					url,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					null
				);
			});
		},
		onOkrejectPortCall(modalData) {
			const params = {
				portcallid: modalData.headerPk.portcallid,
				rejectionreason: modalData.rejectionreason
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.rejectPortCall;
			const title = this.$puiI18n.t('rejectPortCall.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkconfirmPortCall(modalData) {
			const params = {
				portcallid: modalData.headerPk.portcallid
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.confirmPortCall;
			const title = this.$puiI18n.t('confirmPortCall.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		subscribeToModalDialogEvent() {
			// Modal Disable
			this.$puiEvents.$on(`pui-modalDialog-disableVessel-${this.modelName}-ok`, () => {
				if (this.$refs.validateDisable.validate()) {
					this.setShowDisableVessel(false);
					this.$puiEvents.$emit('pui-action-runAction-' + this.modelName);
				}
			});
			this.$puiEvents.$on(`pui-modalDialog-disableVessel-${this.modelName}-cancel`, () => {
				this.setShowDisableVessel(false);
				this.$puiEvents.$off('pui-action-runAction-' + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
				this.modal.disableVessel.fecfinvig = null;
			});
			this.$puiEvents.$on(`pui-modalDialog-disableVessel-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					codbuque: data.codbuque
				};
				this.setShowDisableVessel(true);
			});
			// Modal enable
			this.$puiEvents.$on(`pui-modalDialog-enableVessel-${this.modelName}-ok`, () => {
				this.setShowEnableVessel(false);
				this.$puiEvents.$emit('pui-action-runAction-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-enableVessel-${this.modelName}-cancel`, () => {
				this.setShowEnableVessel(false);
				this.$puiEvents.$off('pui-action-runAction-' + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-enableVessel-${this.modelName}-show`, () => {
				this.setShowEnableVessel(true);
			});
		},
		setShowDisableVessel(action) {
			this.modal.disableVessel.show = action;
		},
		setShowEnableVessel(action) {
			this.modal.enableVessel.show = action;
		},
		setShowmodifeta(action) {
			this.modal.modifeta.show = action;
		},
		onOkUpdateAnchorage(modalData) {
			const params = {
				portcallid: modalData.headerPk.portcallid,
				arrataanchorage: modalData.arrataanchorage,
				arratdanchorage: modalData.arratdanchorage,
				depataanchorage: modalData.depataanchorage,
				depatdanchorage: modalData.depatdanchorage,
				emeataanchorage: modalData.emeataanchorage,
				emeatdanchorage: modalData.emeatdanchorage
			};

			const urlController = this.$store.getters.getModelByName(this.model).url.updateanchorage;

			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('action.updateanchorage');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.model);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					}
				);
			});
		},
		onOkModifEta(modalData) {
			const params = {
				portcallid: modalData.headerPk.portcallid,
				eta: modalData.eta,
				etaconfirmed: modalData.etaconfirmed
			};

			const urlController = this.$store.getters.getModelByName(this.model).url.modifeta;

			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('action.modifeta');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.model);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					}
				);
			});
		},
		onOkModifEtd(modalData) {
			const params = {
				portcallid: modalData.headerPk.portcallid,
				etd: modalData.etd
			};

			const urlController = this.$store.getters.getModelByName(this.model).url.modifetd;

			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('action.modifetd');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.model);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					}
				);
			});
		},
		onShoModifEta(modalData) {
			if (modalData.etaconfirmed == '1') {
				this.dissableCheck = true;
			} else {
				this.dissableCheck = false;
			}
		},
		onOkconfirmDeparture(modalData) {
			const params = {
				portcallid: modalData.headerPk.portcallid
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.confirmDeparture;
			const title = this.$puiI18n.t('confirmDeparture.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,

					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOktransferShipAgency(modalData) {
			const title = this.$puiI18n.t('action.shipAgencyTransfer');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					this.$store.getters.getModelByName('shipagencytransfers').url.transferShipAgency,
					{
						portcallid: modalData.registries.portcallid,
						shipagendid: this.newModel.shipagendid,
						shipagendname: this.newModel.shipagendname,
						shipagendorigin: modalData.registries.shipagendname,
						shipagendoriginid: modalData.registries.shipagendid
					},
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkConfirmTransfer(modalData) {
			const title = this.$puiI18n.t('action.confirmTransfer');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					this.$store.getters.getModelByName('shipagencytransfers').url.confirmTransfer,
					{
						portcallid: modalData.registries.portcallid
					},
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkRejectTransfer(modalData) {
			const title = this.$puiI18n.t('action.rejectTransfer');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					this.$store.getters.getModelByName('shipagencytransfers').url.rejectTransfer,
					{
						portcallid: modalData.registries.portcallid,
						rejectionreason: modalData.rejectionreason
					},
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkEmergencyStart(modalData) {
			const params = {
				portcallid: modalData.headerPk.portcallid,
				datestart: modalData.datestart,
				remarksstart: modalData.remarksstart
			};

			const urlController = this.$store.getters.getModelByName(this.modelemergencyprocess).url.emergencystart;

			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('action.emergencystart');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.model);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onOkEmergencyEnd(modalData) {
			const params = {
				portcallid: modalData.headerPk.portcallid,
				dateend: modalData.dateend,
				remarksend: modalData.remarksend
			};

			const urlController = this.$store.getters.getModelByName(this.modelemergencyprocess).url.emergencyend;

			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('action.emergencyend');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.model);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
						this.$puiNotify.error(message, title, 0, true);
						resolve(false);
					}
				);
			});
		},
		onShowEmergencyEnd(modalData) {
			this.emergencystartdate = modalData.registries.emergencystartdate;
		}
	}
};
</script>
